import { css } from "@emotion/core";
import React from "react";

import Button from "./Button";
import Spacer from "./Spacer";
import SubTitle from "./SubTitle";
import { H1 } from "./Text";

const PageHeader = ({ title, subtitle, buttonProps = null }) => (
  <>
    <H1
      css={css`
        max-width: 90%;
        margin-bottom: 2rem;
      `}
    >
      {title}
    </H1>
    <SubTitle>{subtitle}</SubTitle>

    {buttonProps ? (
      <>
        <Spacer rem={3} />
        <Button gradient {...buttonProps} />
      </>
    ) : null}
  </>
);

export default PageHeader;
