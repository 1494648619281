import React from "react";

import Layout from "../components/Layout";
import PageHeader from "../components/PageHeader";
import Section from "../components/Section";
import SEO from "../components/seo";

const NotFoundPage = () => (
  <Layout>
    <SEO title="404: Not found" />
    <Section>
      <PageHeader
        subtitle="You're out of your element, Donny"
        title="Nothing to see here"
      />
    </Section>
  </Layout>
);

export default NotFoundPage;
